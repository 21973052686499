<template>
	<div class="subheader py-2 py-lg-4" v-bind:class="subheaderClasses" id="kt_subheader">
		<div
			class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
			v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
		>
			<div class="d-flex align-items-center flex-wrap mr-1">
				<h5 class="text-dark font-weight-bold my-2 mr-5">
					{{ title }}
				</h5>
				<ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
					<li class="breadcrumb-item">
						<router-link :to="'/'" class="subheader-breadcrumbs-home">
							<i class="flaticon2-shelter text-muted icon-1x"></i>
						</router-link>
					</li>

					<template v-for="(breadcrumb, i) in breadcrumbs">
						<li class="breadcrumb-item" :key="i">
							<router-link v-if="breadcrumb.route" :to="breadcrumb.route" class="text-muted">
								{{ breadcrumb.title }}
							</router-link>
							<span class="text-muted" v-if="!breadcrumb.route">
								{{ breadcrumb.title }}
							</span>
							<template v-if="breadcrumb.icon">
								<router-link v-if="breadcrumb.icon.route" :to="breadcrumb.icon.route">
									<i :class="[breadcrumb.icon.class, 'text-dark']" v-b-tooltip.hover="breadcrumb.icon.tooltip" />
								</router-link>
								<i v-else :class="breadcrumb.icon.class" v-b-tooltip.hover="breadcrumb.icon.tooltip" />
							</template>
						</li>
					</template>
				</ul>
			</div>
			<!-- <div class="d-flex align-items-center">
				<a href="#" class="btn btn-light font-weight-bold btn-sm">
					Admin Help
				</a>
				<a href="#" class="btn btn-light font-weight-bold btn-sm">
					NOC Alerts
				</a>
				<a href="#" class="btn btn-light font-weight-bold btn-sm">
					SRF
				</a>
			-->
			<div>
				<router-link :to="{ name: 'dash' }">
					<button class="btn btn-light font-weight-bold btn-sm">Dashboard</button>
				</router-link>
				<router-link :to="{ name: 'adminnocalerts' }" v-if="['staff', 'noc', 'admin'].includes(currentUser.role) && $route.path.includes('admin')">
					<button class="btn btn-light font-weight-bold btn-sm">NOC Alerts</button>
				</router-link>
				<router-link :to="{ name: 'releasenotesindex' }" v-if="['staff', 'noc', 'admin'].includes(currentUser.role) && $route.path.includes('admin')">
					<button class="btn btn-light font-weight-bold btn-sm">Release Notes</button>
				</router-link>

				<router-link
					:to="{ name: 'srflist' }"
					v-if="(['admin', 'noc', 'staff'].includes(currentUser.role) || currentUser.showsrf == true) && $route.path !== '/srf'"
				>
					<button class="btn btn-light font-weight-bold btn-sm">SRF List</button>
				</router-link>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.custom-v-dropdown {
	&.dropdown-toggle {
		padding: 0;
		&:hover {
			text-decoration: none;
		}

		&.dropdown-toggle-no-caret {
			&:after {
				content: none;
			}
		}
	}

	&.dropdown-menu {
		margin: 0;
		padding: 0;
		outline: none;
		.b-dropdown-text {
			padding: 0;
		}
	}
}
</style>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'KTSubheader',
	props: {
		breadcrumbs: Array,
		title: String,
	},
	computed: {
		...mapGetters(['layoutConfig', 'currentUser']),

		/**
		 * Check if subheader width is fluid
		 */
		widthFluid() {
			return this.layoutConfig('subheader.width') === 'fluid';
		},

		subheaderClasses() {
			const classes = [];
			const style = this.layoutConfig('subheader.style');
			if (style) {
				classes.push(style);

				if (style === 'solid') {
					classes.push('bg-white');
				}

				if (this.layoutConfig('subheader.fixed')) {
					classes.push('border-top');
				}
			}
			return classes.join(' ');
		},
	},
};
</script>
