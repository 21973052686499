<template>
	<div :class="[{ 'alert-banner-enabled': alertBanner.show }, 'd-flex flex-column flex-root']" v-if="isAuthenticated">
		<!-- begin:: Header Mobile -->
		<KTHeaderMobile></KTHeaderMobile>
		<!-- end:: Header Mobile -->

		<Loader v-if="loaderEnabled" v-bind:logo="loaderLogo" :spinnerClass="'spinner-light'"></Loader>

		<!-- begin::Body -->
		<div class="d-flex flex-row flex-column-fluid page">
			<AlertBanner v-if="alertBanner.show" :message="alertBanner.message" />
			<!-- begin:: Aside Left -->
			<KTAside v-if="asideEnabled"></KTAside>
			<!-- end:: Aside Left -->

			<div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
				<!-- begin:: Header -->
				<KTHeader></KTHeader>
				<!-- end:: Header -->

				<!-- begin:: Content -->
				<div id="kt_content" class="content d-flex flex-column flex-column-fluid">
					<!-- begin:: Content Head -->
					<KTSubheader v-if="subheaderDisplay" v-bind:breadcrumbs="breadcrumbs" v-bind:title="pageTitle" />
					<!-- end:: Content Head -->

					<!-- begin:: Content Body -->
					<div class="d-flex flex-column-fluid">
						<div
							:class="{
								'container-fluid': contentFluid,
								container: !contentFluid,
							}"
						>
							<transition name="fade-in-up">
								<router-view />
							</transition>
						</div>
					</div>
				</div>
				<KTFooter></KTFooter>
			</div>
		</div>
		<KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>
		<KTScrollTop></KTScrollTop>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import KTAside from '@/view/layout/aside/Aside.vue';
import KTHeader from '@/view/layout/header/Header.vue';
import AlertBanner from '@/view/layout/header/AlertBanner.vue';
import KTHeaderMobile from '@/view/layout/header/HeaderMobile.vue';
import KTFooter from '@/view/layout/footer/Footer.vue';
import HtmlClass from '@/core/services/htmlclass.service';
import KTSubheader from '@/view/layout/subheader/Subheader.vue';
import KTStickyToolbar from '@/view/layout/extras/StickyToolbar.vue';
import KTScrollTop from '@/view/layout/extras/ScrollTop';
import Loader from '@/view/content/loaders/Loader.vue';
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from '@/core/services/store/htmlclass.module.js';

export default {
	name: 'Layout',
	components: {
		KTAside,
		KTHeader,
		AlertBanner,
		KTHeaderMobile,
		KTFooter,
		KTSubheader,
		KTStickyToolbar,
		KTScrollTop,
		Loader,
	},
	beforeMount() {
		// show page loading
		this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');

		// initialize html element classes
		HtmlClass.init(this.layoutConfig());
	},
	mounted() {
		setTimeout(() => {
			this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
		}, 2000);
	},
	methods: {},
	computed: {
		...mapGetters(['isAuthenticated', 'breadcrumbs', 'pageTitle', 'layoutConfig', 'alertBanner']),

		loaderEnabled() {
			return !/false/.test(this.layoutConfig('loader.type'));
		},

		contentFluid() {
			return this.layoutConfig('content.width') === 'fluid';
		},

		loaderLogo() {
			return process.env.BASE_URL + this.layoutConfig('loader.logo');
		},

		asideEnabled() {
			return !!this.layoutConfig('aside.self.display');
		},

		toolbarDisplay() {
			// return !!this.layoutConfig("toolbar.display");
			return true;
		},

		subheaderDisplay() {
			return !!this.layoutConfig('subheader.display');
		},
	},
};
</script>
